import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDy8AYlfPON-cSpnmuNJz54LH0l8EZaI4w',
  authDomain: 'codex-liceo-cristiano-penin.firebaseapp.com',
  projectId: 'codex-liceo-cristiano-penin',
  storageBucket: 'codex-liceo-cristiano-penin.appspot.com',
  messagingSenderId: '744710977295',
  appId: '1:744710977295:web:ddf908ada1c0cfd3be707a',
  measurementId: 'G-SDX6P1XR80',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
